import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { appConfig } from '../app.config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';

 
@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient,private toastr :ToastrService) { }
 
    login(email: string, password: string) {
        return this.http.post<any>(appConfig.apiUrl+'/auth/signin', { email: email, password: password })
            .map(res => {
                var user =res.data
                // login successful if there's a jwt token in the response
                console.log(user)
                if (user.user.status == "true" && user.token && user.user.user_type == "Admin") {
                    // console.log(user);
                    // this.toastr.success('Login Successfully')

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user_id',user.user.user_id);
                    localStorage.setItem('type', user.user.user_type);
                    localStorage.setItem('token', JSON.stringify(user.token));
                    localStorage.setItem('email', user.user.email); 
                    localStorage.setItem('image',user.user.image);
                    localStorage.setItem('first_name',user.user.first_name);                  
                }
                return user;
            });
    }
    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');    
        // Check whether the token is expired and return
        // true or false
        const helper = new JwtHelperService();
        // console.log(!helper.isTokenExpired(token));
        return !helper.isTokenExpired(token);
    }

    public isLogout (){
        localStorage.removeItem('token');
        localStorage.clear();
    }
 
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
    }
}