import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css']
})
export class MarketingComponent implements OnInit {
  uploadForm: FormGroup;
  market: any = {};
  public marketlist=[];
  markid: any;
  fileData: File = null;
  previewUrl: any =null;
  no: any = [];
  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.getAllMarket()

  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    })
  }

  getAllMarket() {
    this.spinner.show();
    this.firewireservices.getAllLogos()
      .subscribe(
        response => {
          var data = response.data.result
         
          // if(data.length > 0)
          // {
          // this.marketlist = response.data.result;

          var list = []

          response.data.result.forEach(element => {
            var str = { logo_id: element.logo_id,
               image: appConfig.hs + element.image,
               url: element.url }
            list.push(str)
          });

          
          this.marketlist = list;
          // console.log(this.marketlist)
          this.spinner.hide();
          // }else{
          //   console.log("no data found");
          // }
        }
      )
  }
  
   
  onFileSelectMarketing(event, param, item) {
    this.previewUrl = []
    // this.no =dataid
    // console.log(this.no)
    // this.list = no
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.markid = param;
      this.uploadForm.get('profile').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {

        // this.previewUrl[this.no] = reader.result
        this.marketlist.forEach(element => {
            if(element.logo_id === item.logo_id){
              element.image = reader.result
              // this.previewUrl =
            }
        });

        // this.previewUrl[no.logo_id] = reader.result;
        // this.updateMarket();

        // this.img = this.fileData.name;
      }
    }
  }
  openLogoModal(data) {
    this.market = data
    // console.log(data)
  }

  updateMarket(item) {
    if(this.uploadForm.get('profile').value !=''){
    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    formData.append('logo_id', item.logo_id);
    formData.append('url', item.url);   
    this.spinner.show();

    this.firewireservices.updateLogo(formData)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.toastr.success(response.data.message);
            this.getAllMarket()
            this.spinner.hide();
          } else {
            this.toastr.error(response.data.message);
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
        }
      )
    }else{
      this.toastr.error('Please choose image first')
    }
  }

  DeleteMarket() {
    var datavalue = {
      logo_id: this.market.logo_id
    }
    this.spinner.show();
    this.firewireservices.deleteLogo(datavalue)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.toastr.success(response.data.message);
            this.getAllMarket();
            this.spinner.hide();


          } else {
            this.toastr.error(response.data.message);
            this.spinner.hide();
          }
        },
        err => {
          console.log("error")
        }
      )
  }

}
