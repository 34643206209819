// export const appConfig = {
//         //   apiUrl: 'http://localhost:5000'
//     //  apiUrl:'http://192.168.0.105:3070'
//     // apiUrl:"http://192.168.29.182:5000"
//     // apiUrl:"https://reds.a2hosted.com/aurum/inrest"
//     // apiUrl:"http://208.109.15.102:3001"
//     apiUrl: "https://reds.a2hosted.com:30005",
//     hs:"https://reds.a2hosted.com/"
// };

export const appConfig = {
    //   apiUrl: 'http://localhost:5000'
//  apiUrl:'http://192.168.0.105:3070'
// apiUrl:"http://192.168.29.182:5000"
// apiUrl:"https://reds.a2hosted.com/aurum/inrest"
// apiUrl:"http://208.109.15.102:3001"
apiUrl: "https://surfform.com:30005",
hs:"https://surfform.com/"
};