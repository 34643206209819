import { Component, OnInit } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { IOption } from 'ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { location } from 'ngx-bootstrap/utils/facade/browser';


@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  public transactionlist = [];
  transaction: any = {};
  model: any = {};
  trans_id : any;
  public AddModel;
  public transactionpage = false;
  previewTrans:any = null;


  private options: string[] = ["Completed", "Wip", "Unsuccessful","Returned"];
  selectedPaymentType = "Completed";

  
  private optionsstatus: string[] = ["Purchase"];
  selectedTransactionType = "Purchase";
  // private optionsType: string[] = ["Surfboard","Fins","Longboard","Shoartboard"];
  selectedType = "Surfboard";

  public producttypes = [
    { value: "surfboard", label: "Surfboard" },
    { value: "fins", label: "Fins" },
    { value: "longboard", label: "Longboard" },
    { value: "shoartboard", label: "Shoartboard" }

  ];

  constructor(private firewireservices : FireWireServices,private toastr: ToastrService,private spinner: NgxSpinnerService) { 
    this.getAllTransaction();
  }

  ngOnInit() {
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ .]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  transactionmodal()
  {
    this.transactionpage = true;
  }

  AddTransaction(){
    var datavalue ={

      first_name :this.transaction.first_name ,
      last_name : this.transaction.last_name,
      email :this.transaction.email,
      transaction_type : this.selectedTransactionType,
      product_id :this.transaction.product,
      product_name :this.transaction.product_name ,
      product_type : this.transaction.product_type,
      contact: this.transaction.contact,  
      commision :this.transaction.commision,
      amount :this.transaction.amount,
      paystatus:this.selectedPaymentType,
      

    }
    this.spinner.show();
    this.firewireservices.AddTransaction(datavalue)
    .subscribe(
      response =>{
        // this.getAllTransaction();
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
            this.toastr.success(response.data.message)

            this.getAllTransaction();
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
      },
      err =>{
        console.log("error")
      }
    )
  }


  openTransactionModel(data){
    this.transaction = data;
    this.selectedTransactionType = data.transaction_type;
    this.selectedPaymentType = data.paystatus;
    this.trans_id = data.transaction_id; 
    if(data.image != null){
      this.previewTrans =  data.image;
      // console.log( this.previewTrans)
    }else{
      this.previewTrans = 'assets/images/no_image.jpg';
    }
  }

  getAllTransaction()
  {
    this.spinner.show(); 
    this.firewireservices.getAllTransaction()
    .subscribe(
      response =>{
        setTimeout(function() {
          this.spinner.hide();
          }.bind(this),response);
        this.transactionlist = response.data.result;
        this.searchTransactionList = this.transactionlist;
        // console.log("transaction",this.transactionlist)
      },
      err =>{
        console.log("error");
      }
    )
  }
  cal(){
    
    this.transaction.commision = ((this.transaction.amount *3 ) / 100) 
  }
  UpdateTransaction()
  {

    if(this.transaction.transaction_id != "" && this.transaction.first_name != "" && this.transaction.seller_name != ""
     && this.transaction.email != "" && this.transaction.product_id != "" && this.transaction.product_name != ""
     && this.transaction.contact != "" && this.transaction.commision != "" && this.transaction.amount !=""){

    var datavalue ={
      transaction_id : this.transaction.transaction_id,
      first_name :this.transaction.first_name ,
      seller_name : this.transaction.seller_name,
      email :this.transaction.email,
      transaction_type : this.selectedTransactionType,
      product_id :this.transaction.product_id,
      product_name :this.transaction.product_name ,
      product_type :this.transaction.product_type ,
      contact: this.transaction.contact,  
      commision :this.transaction.commision,
      amount :this.transaction.amount,
      paystatus:this.selectedPaymentType, 
    }
    this.spinner.show();
    this.firewireservices.updateTransaction(datavalue)
    .subscribe(
      response =>{
        // this.getAllTransaction();
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
            // this.transactionpage = false;
            this.toastr.success(response.data.message)

            this.getAllTransaction();
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message);
        }
      },
      err =>{
        console.log("error");
      }
    )
    }
  }

  DeleteTransaction(){

    var datavalue ={
      transaction_id : this.transaction.transaction_id
    }
    this.spinner.show();
    this.firewireservices.DeleteTransaction(datavalue)
    .subscribe(
      response =>{
        // this.getAllTransaction();
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
            this.toastr.success(response.data.message)

            this.getAllTransaction();
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
      },
      err =>{
        console.log("error");
      }
    )
  }
  public searchTransactionPage = false;
  searchTransactionModal(){
    this.searchTransactionPage = !this.searchTransactionPage;
  }

  srch:any={}
  public searchTransactionList;
  searchTransaction()
  {
    var data ={
      product_name : this.srch.product_name
    }
    this.spinner.show(); 
    this.firewireservices.getAllSearchTransaction(data)
    .subscribe(
      response =>{
        setTimeout(function() {
          this.spinner.hide();
          }.bind(this),response);
        this.searchTransactionList= response.data.result      
     
      }
    )
  }
}
