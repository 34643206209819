import { Component, OnInit } from '@angular/core';
import { IOption } from 'ng-select';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  model: any = {}; 
  sData:any = {};
  tooglepool:boolean=true;
  userType:any={};
  Citys: any = ['Florida','South Dakota', 'Tennessee', 'Michigan']
  uType:any = "";
  private options: string[] = ["Customer","Admin"];
  selectedUsertType = "Customer";
  constructor(private firewireservices : FireWireServices,private _route: ActivatedRoute,private _router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }


  register() {
    
    var datavalue ={
      first_name:this.model.first_name,
      last_name:this.model.last_name,
      user_name:this.model.user_name,
      address:this.model.address,
      country:this.model.country,
      state:this.model.state,
      city:this.model.city,
      phone:this.model.phone,
      post_code:this.model.post_code,
      // birthdate:this.model.birthdate,
      email:this.model.email,
      password:this.model.password,
      "created":new Date(),
      user_type :"admin"
    }
    this.spinner.show(); 
    this.firewireservices.registerInsert(datavalue)
      .subscribe(
        data => { 
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),data);
   
          var custdetails = data; 
          // this.toastr.info('Registered Successfully')
          this._router.navigate(['login']);
         
        },
        error => {
          console.log(error);
        });
  }
}
