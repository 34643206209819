import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './view/login/login.component';
import { RegisterComponent } from './view/register/register.component';
import { HeaderComponent } from './view/header/header.component';
import { FooterComponent } from './view/footer/footer.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { UsersComponent } from './view/users/users.component';
import { TransactionComponent } from './view/transaction/transaction.component';
import { ProductComponent } from './view/product/product.component';
import { LogoutComponent } from './view/logout/logout.component';
import { AuthGuard } from './guards';
import { LocationComponent } from './view/location/location.component';
import { AuthenticationService } from './services';
import { MarketingComponent } from './view/marketing/marketing.component';
// import { LogoComponent } from './view/logo/logo.component';
import { BrandComponent } from './view/brand/brand.component';
import { FooterSettingsComponent } from './view/footer-settings/footer-settings.component';
import { ReviewComponent } from './view/review/review.component';
import { VideoComponent } from './view/video/video.component';
// import { AuthService } from 'angularx-social-login';


const routes: Routes = [

  {path:"login",component:LoginComponent},
  {path:"register",component:RegisterComponent},
  {path:"header",component:HeaderComponent,canActivate:[AuthGuard]},
  {path:"footer",component:FooterComponent,canActivate:[AuthGuard]},
  {path:"dashboard",component:DashboardComponent,canActivate:[AuthGuard]},
  {path:"users",component:UsersComponent,canActivate:[AuthGuard]},
  {path:"transaction",component:TransactionComponent,canActivate:[AuthGuard]},
  {path:"product",component:ProductComponent,canActivate:[AuthGuard]},
  {path:"location",component:LocationComponent,canActivate:[AuthGuard]},
  {path:"marketing",component:MarketingComponent,canActivate:[AuthGuard]},
  // {path:"logo",component:LogoComponent,canActivate:[AuthGuard]},
  {path:"brand",component:BrandComponent,canActivate:[AuthGuard]},
  {path:"footer-settings",component:FooterSettingsComponent,canActivate:[AuthGuard]},
  {path:"review",component:ReviewComponent,canActivate:[AuthGuard]},

  {path:"video",component:VideoComponent,canActivate:[AuthGuard]},

  {path:"logout",component:LogoutComponent},
  {path:"",redirectTo:"/dashboard",pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
