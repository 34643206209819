import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {

  userList = [];
  reviewList = [];
  loggedInUser: any = {}
  public flagdata = false;
  reviewData: any;
  userId: any;
  selectedReview: any;

  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder,
    private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.getAllReview();

  }

  ngOnInit() {
  }

  openReviewModel(data) {
    this.selectedReview = data
    console.log(this.selectedReview)
  }

  deleteReview() {
    this.spinner.show();
    var data = {
      id: this.selectedReview.id
    }
    this.firewireservices.deleteReview(data)
      .subscribe(
        res => {
          if (res.data.status == 1) {
            this.getAllReview();
            this.toastr.success(res.data.message);
            this.spinner.hide();
          } else {
            this.toastr.warning(res.data.message)
            this.spinner.hide();
          }
        },
        error => {
          console.log(error);
          this.spinner.hide();
        });
  }

  openFlagModal(item) {
    console.log(item)
    this.userId = item.id
    this.flagdata = true
  }

  review(value) {
    console.log(value)
    this.reviewData = value;
    this.changereviewStatus();
  }

  changereviewStatus() {
    let sendData = {
      reviewId: this.userId,
      isApproved: this.reviewData
    }
    console.log(sendData)
    this.spinner.show();
    this.firewireservices.reviewStatus(sendData)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.toastr.success(response.data.message)
            this.getAllReview();
          } else {
            this.toastr.error(response.data.message)
          }
        },
        error => {
          console.log(error);
        });
  }

  getAllReview() {
    this.spinner.show();
    this.firewireservices.getAllReview()
      .subscribe(
        response => {
          var data = response.data;
          console.log(data)
          if (data.status == 1) {
            this.reviewList = data.result;
          } else {
            this.reviewList = [];
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )

  }

}
