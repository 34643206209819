import { Component, OnInit } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { NgOption } from '@ng-select/ng-select';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConfig } from 'src/app/app.config';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  public userlist;
  users: any = {};
  model: any = {};
  locationL:any={};
  public editModel;
  public userpage = false;
  public usersearchpage = false;
  name : any ={};
  useraddress : any ={};
  useremail : any ={};
 public userimg : any ={};
  file : any = {};
  fileid : any;
  public transactionlist = [];
  public formFlag = false;
  public searchuserlist;
  transaction: any = {};
  public product: any = {};
  public selectedColor: any = [];
  public selectedSystem: any = [];
  public selectedSize: any = [];
  public locationdetailslist:any = [];

  public FileList;
  fileData: File = null;
  previewUrl:any = null;
  uid = localStorage.getItem("user_id");
  userid 
  previewTrans:any = null;
  // profileimg = JSON.parse(sessionStorage.getItem('image'));
  uploadForm: FormGroup;
  public transactionpage = false;
  selectedUser: any;
  public productpage = false;
  trans_id : any;
 
  public shippingType = [
    { value: "1", label: "Pick up" },
    { value: "2", label: "Fix rate" }, 
  ];
  public locoptions: string[] = ["Retailer", "Warehouse", "Customer"];
  public producttypes = [
    { value: "surfboard", label: "Surfboard" },
    { value: "fins", label: "Fins" },
    { value: "longboard", label: "Longboard" },
    { value: "shoartboard", label: "Shoartboard" }

  ];
  utype: any = ['Florida', 'South Dakota', 'Tennessee', 'Michigan']
  // public utype: Array<NgOption> = [
  //   {label: 'Belgium', value: 'BE'},
  //   {label: 'Luxembourg', value: 'LU'},
  //   {label: 'Netherlands', value: 'NL'}
  // ];
  public selectedstatus:string[] = ["Used","New"]

 
  private options: string[] = ["Completed", "Wip", "Unsuccessful","Returned"];
  selectedPaymentType = "Completed";

  private optionstype: string[] = ["Admin","Customer"];
  selectedUserType = "Customer";

  private optionsstatus: string[] = ["Purchase", "Sale"];
  selectedTransactionType = "Purchase";
  private optionsType: string[] = ["Surfboard","Fins"];
  selectedType = "Surfboard";
  public selectedRType: any = "Retailer";


  constructor(private firewireservices : FireWireServices,private toastr: ToastrService,private formBuilder: FormBuilder,private spinner: NgxSpinnerService ) { 
    this.getAllusers();
    
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    })
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  getAllusers(){
    this.spinner.show(); 

    this.firewireservices.getAllUsers()
    .subscribe(
      response =>{
        setTimeout(function() {
          this.spinner.hide();
          }.bind(this),response);
        var sdata = response.data.result
       var list = []
       var str;
        sdata.forEach(element => {
          if(element.user_id != this.uid){
            if(element.image == null){
              str = {user_id:element.user_id,
                first_name:element.first_name,
                // last_name:element.last_name,
                user_type:element.user_type,
                country:element.country,
                state:element.state,
                city:element.city,
                post_code:element.post_code,
                address:element.address,
                phone:element.phone,
                email:element.email,
                image:'assets/images/Picture4.png'} 
                
            }else{
                str = {user_id:element.user_id,
                first_name:element.first_name,
                // last_name:element.last_name,
                user_type:element.user_type,
                country:element.country,
                state:element.state,
                city:element.city,
                post_code:element.post_code,
                address:element.address,
                phone:element.phone,
                email:element.email,
                image:appConfig.hs+element.image}
            }
            
            list.push(str)
          }
        });
        this.userlist = list;
        this.searchuserlist = this.userlist;
        // console.log("userlist",this.userlist);
      }
    )

  }
srch:any={};
  searchUser()
  {
    var data ={
      first_name:this.srch.first_name,
    }
    this.spinner.show(); 
    this.firewireservices.getAllSearch(data)
    .subscribe(
      response =>{
        setTimeout(function() {
          this.spinner.hide();
          }.bind(this),response);
          var sdata = response.data.result
          var list = []
           sdata.forEach(element => {
             if(element.user_id != this.uid){
               if(element.image == null){
                 var str = {user_id:element.user_id,
                   first_name:element.first_name,
                  //  last_name:element.last_name,
                   user_type:element.user_type,
                   country:element.country,
                   state:element.state,
                   city:element.city,
                   post_code:element.post_code,
                   address:element.address,
                   phone:element.phone,
                   email:element.email,
                   image:'assets/images/Picture4.png'} 
                   
               }else{
                 var str = {user_id:element.user_id,
                   first_name:element.first_name,
                  //  last_name:element.last_name,
                   user_type:element.user_type,
                   country:element.country,
                   state:element.state,
                   city:element.city,
                   post_code:element.post_code,
                   address:element.address,
                   phone:element.phone,
                   email:element.email,
                   image:appConfig.hs+element.image}
               }
               
               list.push(str)
             }
           });
        this.searchuserlist= list      
        // this.userlist = list
        // console.log("userlist",this.userlist);
      }
    )

  }

  searchModal()
  {
    this.usersearchpage = !this.usersearchpage;
  }
  usermodal(){
    this.userpage = true;
  }
  postCode:any={}
  selectedID:any={};
  phNo:any={}
  userscity:any={};
  userstate:any={};
  usercountry:any={};
  openRegisterModel(data){
    this.formFlag = true;
    this.model = data;
    this.name = data.first_name;
    this.useremail = data.email;
     this.selectedUserType = data.user_type;
    this.previewUrl =  data.image
    this.selectedID = data.user_id;
     this.phNo = data.phone;

    if( data.address !="")
    {
      this.useraddress = data.address  + ","
    }
    if( this.users.city !="")
    {
      this.userscity = data.city + ","
    }
    if( data.state  !="")
    {
      this.userstate = data.state + ","
    }            
    if( data.post_code !="")
    {
      this.postCode = data.post_code + ","
    }
   
    if( data.country !="")
    {
      this.usercountry = data.country
    }

    this.getAllTransaction();
    console.log( this.model )
    
  }
  deleteModalvalue(item){
    this.model = item;
  }
  openTransactionModel(data){
    this.transaction = data;
    this.selectedTransactionType = data.transaction_type;
    this.selectedPaymentType = data.paystatus;
    this.trans_id = data.transaction_id;
    if(data.image != null){
      this.previewTrans = data.image;
    }else{
      this.previewTrans = 'assets/images/no_image.png';
    }
  

  }
  transactionmodal()
  {
    this.transactionpage = true;
  }


  onClick(data){
    this.userid = data.user_id;

    this.getAllProduct()
  }
 

  register() {
    
    var datavalue ={
      first_name:this.model.first_name,
      // last_name:this.model.last_name,
      // user_name:this.model.user_name,
      // address:this.model.address,
      // country:this.model.country,
      // state:this.model.state,
      // city:this.model.city,
      phone:this.model.phone,
      // post_code:this.model.post_code,
      // birthdate:this.model.birthdate,
      email:this.model.email,
      password:this.model.password,
      "created":new Date(),
      user_type :"admin"
    }
    this.spinner.show(); 
    this.firewireservices.registerInsert(datavalue)
      .subscribe(
        response => { 
            
          if(response.data.status == 1){
            setTimeout(function() {
              this.spinner.hide();
              }.bind(this),response);
              this.userpage = false;
              this.toastr.success(response.data.message)

              this.getAllusers();
          }else{
            this.toastr.error(response.data.message)
          }
         
        },
        error => {
          console.log(error);
        });
  }

  updateUser() { 
    if(this.model.user_id != "" && this.model.first_name != "" 
      && this.model.address != "" && this.model.country != ""
      && this.model.state != "" && this.model.city != "" && this.model.post_code != "" && this.model.phone != ""){

        if(this.model.user_id !=null && this.model.first_name !=null && this.model.address !=null &&
          this.model.state != null && this.model.city != null && this.model.post_code != null &&
          this.model.phone != null){ 
      
    
    var datavalue ={
      user_id : this.model.user_id,
      first_name:this.model.first_name,
      // last_name:this.model.last_name,
      address:this.model.address,
      country:this.model.country,
      state:this.model.state,
      city:this.model.city,
      phone:this.model.phone,
      post_code:this.model.post_code,      
      // email:this.model.email,     
      user_type : this.selectedUserType
    }

    console.log(datavalue)
    this.spinner.show();
    this.firewireservices.UpdateUser(datavalue)
      .subscribe(
        response => {          
         
          if(response.data.status == 1){
            setTimeout(function() {
              this.spinner.hide();
              }.bind(this),response);
              // this.userpage = false;
              this.toastr.success(response.data.message)

              this.getAllusers();
          }else {
            setTimeout(function() {
              this.spinner.hide();
              }.bind(this),response);
            this.toastr.error(response.data.message)
          }
        },
        err => {
          console.log(err);
        }
      )
    }
  }
  }

  deleteUser()
  {
    var data ={
      user_id:this.model.user_id
    }
    this.spinner.show();
    this.firewireservices.DeleteUser(data)
    .subscribe(
      response =>{
        
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            this.userpage = false;
            this.toastr.success(response.data.message)

            this.getAllusers();
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
      },
      err =>{
        console.log(err);
      }
    )
  }

  onFileSelect(event,param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.formData.append('file', file, file.name);  
      // console.log(file);
      this.fileid = param;  
      this.uploadForm.get('profile').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
  
      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
        var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.previewUrl = reader.result; 
        // this.img = this.fileData.name;
        this.updateUserProfiledata();
      }
      }
  }

  getAllTransaction()
  {
    this.spinner.show(); 
    this.firewireservices.getAllTransaction()
    .subscribe(
      response =>{
        setTimeout(function() {
          this.spinner.hide();
          }.bind(this),response);
          var list = [];
          response.data.result.forEach(element => {
            if( element.user_id == this.selectedID){
              list.push(element)
            }
          });
        this.transactionlist = list;
        console.log( this.transactionlist )
        // console.log("transaction",this.transactionlist)
      },
      err =>{
        console.log("error");
      }
    )
  }
  updateUserProfile()
  {
    console.log(this.userid);
    var data ={
      user_id : JSON.parse(this.userid)
    }

    this.firewireservices.getUsersByID(data)
    .subscribe(
      response =>{
        this.users = response.data;
        // console.log("users",this.users);
        this.editcliendataResponse(this.users)
      }
    )
  }

  editcliendataResponse(data) {
    // console.log("slugurl",data);

 
    this.model.first_name = data.result[0].first_name;
    // this.model.last_name = data.result[0].last_name;
    this.model.user_type = data.result[0].user_type;
    this.model.email = data.result[0].email;    
     
  }
  
  updateUserProfiledata()
  {
    this.spinner.show();
    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    formData.append('user_id',JSON.parse(this.userid)); 
    
    // var data ={
    //       user_id : JSON.parse(this.userid),
    //       first_name : this.model.first_name,
    //       last_name : this.model.last_name,
    //       user_type:this.model.user_type,
    //       email:this.model.email,         
    //       image:this.uploadForm.get('profile').value
    //   }

    this.firewireservices.Updatefile(formData)
    .subscribe(
      response =>{
      // console.log("Updated Profile Successfully")
      setTimeout(function() {
        this.spinner.hide();
        }.bind(this),response);

        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
            this.toastr.success(response.data.message)

            // this.getAllusers();
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
      },
      err =>{
        console.log("error");
      }
    )
  }

  // showSuccess() {
  //   this.toastr.success('Hello world!', 'Toastr fun!', {
  //     timeOut: 200000
  //   });
  // }


  UpdateTransaction()
  {
    if(this.transaction.transaction_id != "" && this.transaction.first_name != "" && this.transaction.seller_name != ""
    && this.transaction.email != "" && this.transaction.product_id != "" && this.transaction.product_name != ""
    && this.transaction.contact != "" && this.transaction.commision != "" && this.transaction.amount !=""){

   var datavalue ={
     transaction_id : this.transaction.transaction_id,
     first_name :this.transaction.first_name ,
     seller_name : this.transaction.seller_name,
     email :this.transaction.email,
     transaction_type : this.selectedTransactionType,
     product_id :this.transaction.product_id,
     product_name :this.transaction.product_name ,
     product_type :this.selectedType  ,
     contact: this.transaction.contact,  
     commision :this.transaction.commision,
     amount :this.transaction.amount,
     paystatus:this.selectedPaymentType, 
   }
    this.spinner.show();

    this.firewireservices.updateTransaction(datavalue)
    .subscribe(
      response =>{
       
        if(response.data.status == 1){

          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.getAllTransaction();
          this.toastr.success(response.data.message)
          this.transactionpage = false;
        }else{
          this.toastr.success("Not Updated")
        }
        
      },
      err =>{
        console.log("error");
      }
    )
  }
}

public productList = [];
  getAllProduct() {
    var data = {
      user_id: this.userid
    }
    this.spinner.show();
    this.firewireservices.getProductByuserID(data)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            var data = response.data.result;
            var datamsg = response.data;
            
            if (data.length > 0) {

              for (var i =0; i < data.length;i++)
            {
              if(data[i].images != '' && data[i].images != null){
                data[i].image = appConfig.hs +  data[i].images[0].image
              }else{
                data[i].image= 'assets/images/no_image.jpg'

              }

              this.productList = data;
            
             } 
             
              this.spinner.hide();
            } else {
              // console.log(datamsg.message)
              this.spinner.hide();
            }
          }
        },
        err => {
          this.spinner.hide();

        }
      )

  }

  public imageList = [];
  img:any={};
  public isImg;
  getProductImages(proid){
    // var ProductID = localStorage.getItem('ProductID');
    var id={
      product_id: proid 
    }
    this.spinner.show();
    this.firewireservices.getProductImagesByProduct_id(id)
    .subscribe(
      response => {
        
        var imgList = response.data.result; 
        var str;
        var list = []

        if(response.data.status != 0){
          imgList.forEach(element => {
            
             str={path: appConfig.hs + element.image}
            list.push(str)
          });
          this.isImg = 1;
        }else{
          this.img="assets/images/no_image.jpg"
          this.isImg = 0;
             
        }
       
        // this.imageList = list;
        const slideItems = [
      { path: 'assets/images/1.png', title: 'Title 1' },
      { path: 'assets/images/a.jpg', title: 'Title 2' }, 
    ];

         this.imageList = list;
        // console.log(this.slider.items)
        this.spinner.hide();
        // console.log("cartlist",this.cartlist)

      },

    )
      
  }

  userID:any={}
  public ShippingValues;
  openProductModel(data) { 
this.productpage = true;
    this.product = data;
    this.userID = data.user_id;
    this.getAllLocation(); 
    // console.log(data)



     if ( data.fixrate !== "null" &&  data.fixrate != 'undefined') {
        this.ShippingValues = "2";
        this.product.shippingType = "2"
        this.product.fixrate = data.fixrate;
      } 
      if (data.pic_up !== "null" && data.pic_up != 'undefined') {
        this.ShippingValues = "1";
        this.product.shippingType = "1"
        this.product.pic_up =  data.pic_up;
      } 

    this.getProductImages(this.product.product_id)
    // if (data.image != null) {
    //   this.previewUrl ='https://dhol.app/'+ data.image
    // } else {
      
    //   this.previewUrl = 'assets/images/no_image.png'
    // }

    // console.log(this.previewUrl)
    if (data.color != null) { 
    var dd =data.color
    // var sub = dd.replace(/\"/g, "");
    // this.selectedColor = sub;
     var test = dd.toString().replace(/"/g, "");
      var sub = test.split(',');
      this.selectedColor = sub;
      // console.log(sub)
    }
    if (data.fin_system != null) {
      this.selectedSystem = data.fin_system;
    }
    if (data.size != null) {
      var ss =data.size
    var sizes = ss.toString()
    var st = sizes.split(',')
    this.selectedSize = st; 
    // console.log(st)
    }



    // console.log(data)
  }

  ShippMethod(data) {
    // console.log(data);
    if (data != undefined) {
      this.ShippingValues = data.value;
      if (this.ShippingValues == "1") {
        this.product.pic_up = null
        this.product.fixrate = null
      } 
      if(this.ShippingValues == "2") {
        this.product.fixrate = null
        this.product.pic_up = null
      }
    } else {
      // console.log("no data found");
      this.ShippingValues = 0;
    }
  }

  updateProduct() {
    // var datavalue ={
    //   product_id : this.product.product_id,
    //   name : this.product.name,
    //   type : this.product.type,
    //   status : this.product.status,
    //   location:this.product.location,
    //   owner : this.product.owner,
    //   price : this.product.price,
    //   updated : new Date()
    // }
    // console.log(this.product)

    if(this.product.product_id != "" && this.product.pro_id != "" &&  
      this.product.name !="" &&  this.product.type!="" &&
       this.product.status!="" && this.product.location !="" &&  this.product.technology!="" &&  this.product.price !="" && this.product.brand_name!=""){

      
    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    formData.append('product_id', this.product.product_id); 
    formData.append('pro_id', this.product.pro_id); 
    formData.append('name', this.product.name);
    formData.append('type', this.product.type);
    formData.append('status', this.product.status);
    formData.append('location', this.product.location);
    // formData.append('designtype', this.product.designtype); 
    formData.append('technology', this.product.technology);
    formData.append('price', this.product.price);
    formData.append('brand_name', this.product.brand_name); 

    if(this.product.fixrate != 'null' ||this.product.fixrate != undefined ||this.product.fixrate != "" ){
      formData.append('fixrate', this.product.fixrate);
    }
    if(this.product.pic_up != 'null' || this.product.pic_up != undefined || this.product.pic_up != ""){
      formData.append('pic_up', this.product.pic_up);
    }
    if(this.product.tax != null){
      formData.append('tax', this.product.tax);
    }
    


    // formData.append('fin_system', this.selectedSystem);
    // formData.append('color', this.selectedColor);
    // formData.append('size', this.product.size);

    this.spinner.show();

    this.firewireservices.updateProduct(formData)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            // this.productpage = false;
            this.toastr.success(response.data.message)
            this.getAllProduct();
          } else {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            this.toastr.error(response.data.message);
          }
        },
        err => {
          console.log("error");
        }
      )
       }
  }

  getAllLocation() {
    var data = {
      user_id: this.userID 
    }
    // console.log(data)
    this.spinner.show();
    this.firewireservices.getLocationByuserID(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            var list = []
            data.forEach(element => {
              var str = { label: element.name, value: element.name }
              list.push(str)
            });
            this.locationdetailslist = list;
            // console.log("location", this.locationdetailslist);
            this.spinner.hide();
          } else {
            // console.log("no data found");
            this.spinner.hide();
          }

        }, err => {
          console.log("error");
          this.spinner.hide();

        }
      )
  }

  addLocation() {
    // alert()
    
    
    if(this.locationL.loc_id !=undefined && this.locationL.loc_name !=undefined && this.locationL.contact_name !=undefined && this.locationL.address !=undefined && this.locationL.country !=undefined &&this.locationL.state !=undefined && this.locationL.city !=undefined && this.locationL.post_code != undefined && this.locationL.email !=undefined && this.locationL.phone_number!=undefined )
    {
  
    var datavalue = {
      loc_id: this.locationL.loc_id,
      name: this.locationL.loc_name,
      user_id: this.userID,
      contact_name: this.locationL.contact_name,
      address: this.locationL.address,
      country: this.locationL.country,
      state: this.locationL.state,
      city: this.locationL.city,
      post_code: this.locationL.post_code,
      email: this.locationL.email,
      phone_number: this.locationL.phone_number,
      user_type: this.selectedRType,
  
      }
      // console.log(datavalue)
  
    this.spinner.show();
    // console.log(datavalue)
    this.firewireservices.AddLocation(datavalue)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.getAllLocation();
            this.spinner.hide();
            this.toastr.success(response.data.message);
            document.getElementById('openModalMarket').click()
  
  
            } else {
              this.spinner.hide();
              this.toastr.error(response.data.message);
            }
  
          },
          err => {
            console.log("error");
            this.spinner.hide();
          }
        )
    }
  }





}
