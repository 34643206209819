import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services';
// import { FacebookLoginProvider, AuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
// import { ToastrService } from 'ngx-toastr';
// import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
  
})
export class LoginComponent implements OnInit {
  user: any = {};
  model: any = {};
  icon : any ={}
  // usersocial: SocialUser
  returnUrl: string;
  loading = false;
  isTextFieldType: boolean;
  // public toasterconfig: ToasterConfig =
  // new ToasterConfig({
  //   tapToDismiss: true,
  //   timeout: 5000
  // });
  constructor( private authenticationService: AuthenticationService,private toastr: ToastrService,private spinner: NgxSpinnerService,private _router: Router, private _route: ActivatedRoute) { }

  ngOnInit() {
 
    
  }

  logindd(){
  if(this.user.email ==null && this.user.password ==null)
  {
    return false;
  }else{
    this.spinner.show(); 
    this.authenticationService.login(this.user.email, this.user.password)
        .subscribe(
            data => {
              setTimeout(function() {
                this.spinner.hide();
                }.bind(this),data);
                localStorage.setItem("loggedInUser", JSON.stringify(data.user))
                  this._router.navigate(['/dashboard']);  
                
       
              // setTimeout(() => {      
              //   this.spinner.hide();
              // }, 5000);
                  // this.toasterService.pop('success', 'Login successfully');
                // this._router.navigate([this.returnUrl]);  
                       
            },  
            error => {
              console.log("'Error', 'Username or Password Invalid'");
              setTimeout(function() {
                this.spinner.hide();
                }.bind(this),error);
              this.toastr.error('Username or Password Invalid')
              // this.toastr.success('Deck Deleted Successfully', '');
              // this.toastr.error('Login Failed!', '');
            }
            );
  }
  
   }

   togglePasswordFieldType(id) {
    this.icon = id    
    this.isTextFieldType = !this.isTextFieldType;
  }

}
