import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators'; 
import { appConfig } from 'src/app/app.config';
import { MapsAPILoader } from '@agm/core';
declare var google : any

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  public productList = [];
  public searchproductList =[];
  public product: any = {};
  model: any = {};
  public AddModel;
  public productsearchpage = false;
  public productpage = false;
  public flagdata = false;
  public FileList;
  locationL:any={};
  file: any = {};
  fileid: any;
  p: number = 1;
  fileData: File = null;
  previewUrl: any = null;
  uploadForm: FormGroup;
  tempProdList = [];
  flagvalue : any;
  @ViewChild('search', {static: false}) searchElementRef: ElementRef;
  lat = 0
  lng = 0
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  productId: any;

  private coloroptions: string[] = ["black", "blue", "White"];
  public selectedColor: any = [];
  private systemoptions: string[] = ["Five Fin - Futures", "Five Fin - FCS"];
  public selectedSystem: any = [];
  private sizeoptions: string[] = ["5'7 x 18 7/8 x 2 3/8 - 27.5L"];
  public selectedSize: any = [];
  public selectedstatus:string[] = ["Used","New"]
  public selectedOwner:string[] = ["OWNER","FIREWIRE"]
  public locationdetailslist:any = [];
  userid

  public selectedType: any = "Retailer";

  public shippingType = [
    { value: "1", label: "Pick up" },
    { value: "2", label: "Fix rate" }, 
  ];
  public locoptions: string[] = ["Retailer", "Warehouse", "Customer"];
  public producttypes = [
    { value: "surfboard", label: "Surfboard" },
    { value: "fins", label: "Fins" },
    { value: "longboard", label: "Longboard" },
    { value: "shoartboard", label: "Shoartboard" }

  ];

  public boardStatusList = [
    { value: "1", label: "For Sale" },
    { value: "2", label: "Stolen" }, 
    { value: "3", label: "Quiver" }
  ];

  modelChanged: Subject<string> = new Subject<string>();
  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder,
     private toastr: ToastrService, private spinner: NgxSpinnerService, private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) {
    this.userid = localStorage.getItem('user_id');
    this.getAllProduct();

    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(model => {
        // console.log(model);
        this.searchProduct();
        
      });
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    })
  }

  changed(text: string) {
    this.modelChanged.next(text);
}

findAdress() {
  this.mapsAPILoader.load().then(() => {
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        // some details
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        let address = place.formatted_address;
        let web_site = place.website;
        let name = place.name;
        let zip_code = place.address_components[place.address_components.length - 1].long_name;
        //set latitude, longitude and zoom
        let latitude = place.geometry.location.lat();
        let longitude = place.geometry.location.lng();
        let zoom = 12;
        console.log(latitude, longitude, address)
        this.lat = latitude;
        this.lng = longitude;
        this.address = address
        this.product.pic_up = this.address
      });
    });
  });
}

  AddProduct() {
    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    formData.append('name', this.product.name);
    formData.append('type', this.product.type);
    formData.append('status', this.product.status);
    formData.append('location', this.product.location);
    formData.append('owner', this.product.owner);
    formData.append('price', this.product.price);

    this.spinner.show();

    this.firewireservices.AddProduct(formData)
      .subscribe(
        response => {
          // setTimeout(function() {
          //   this.spinner.hide();
          //   }.bind(this),response);
          // this.getAllProduct();
          if (response.data.status == 1) {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            // this.userpage = false;
            this.toastr.success(response.data.message)
            this.getAllProduct();
          } else {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            this.toastr.error(response.data.message)
          }
        },
        err => {
          console.log("error")
        }
      )
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ .]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  searchProductModal() {
    this.productsearchpage = !this.productsearchpage ;

  }

  srch: any = {}
  searchProduct() {
    var data = {
      name: this.srch.name
    }
    this.spinner.show();
    // this.tempProdList = this.productList.filter(x=> x.)
    this.firewireservices.getAllSearchProduct(data)
      .subscribe(
        response => {
          setTimeout(function () {
            this.spinner.hide();
          }.bind(this), response);

          this.searchproductList = response.data.result

        }
      )
  }

  getAllProduct() {
    this.spinner.show();

    this.firewireservices.getAllProduct()
      .subscribe(
        response => {
          setTimeout(function () {
            this.spinner.hide();
          }.bind(this), response);
          this.productList = response.data.result;
          console.log('productList',this.productList)
          this.searchproductList = this.productList;
          // console.log("productList",this.productList);
        }
      )
  }


  copyLink(text:string) {
    if(text != null){
      const event = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', text);
        e.preventDefault();
        // ...('copy', e), as event is outside scope
        // document.removeEventListener('copy', e);
    }
    document.addEventListener('copy', event);
    document.execCommand('copy');
    this.toastr.success('Text Copied')
    }else{
      console.log('URL Not Found')
    }
   
}

addLocation() {
  // alert()
  
  
  if(this.locationL.loc_id !=undefined && this.locationL.loc_name !=undefined && this.locationL.contact_name !=undefined && this.locationL.address !=undefined && this.locationL.country !=undefined &&this.locationL.state !=undefined && this.locationL.city !=undefined && this.locationL.post_code != undefined && this.locationL.email !=undefined && this.locationL.phone_number!=undefined )
  {

  var datavalue = {
    loc_id: this.locationL.loc_id,
    name: this.locationL.loc_name,
    user_id: this.userID,
    contact_name: this.locationL.contact_name,
    address: this.locationL.address,
    country: this.locationL.country,
    state: this.locationL.state,
    city: this.locationL.city,
    post_code: this.locationL.post_code,
    email: this.locationL.email,
    phone_number: this.locationL.phone_number,
    user_type: this.selectedType,

    }
    console.log(datavalue)

  this.spinner.show();
  // console.log(datavalue)
  this.firewireservices.AddLocation(datavalue)
    .subscribe(
      response => {
        if (response.data.status == 1) {
          this.getAllLocation();
          this.spinner.hide();
          this.toastr.success(response.data.message);
          document.getElementById('openModalMarket').click()


          } else {
            this.spinner.hide();
            this.toastr.error(response.data.message);
          }

        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }
}
  
  // getData(item){
  //   console.log(item)

  //   var slugData={
  //     slug_url:"http://localhost:4200/scan/"+item.product_id,
  //     product_id:item.product_id
  //   }
    
  //   console.log(slugData); 
  //   this.spinner.show();

  //   this.firewireservices.updateSlug(slugData)
  //     .subscribe(
  //       response => {
  //         if (response.data.status == 1) {
  //           setTimeout(function () {
  //             this.spinner.hide();
  //           }.bind(this), response);
  //           this.productpage = false;
  //           this.toastr.success(response.data.message)
  //           this.getAllProduct();
  //         } else {
  //           setTimeout(function () {
  //             this.spinner.hide();
  //           }.bind(this), response);
  //           this.toastr.error(response.data.message);
  //         }
  //       },
  //       err => {
  //         console.log("error");
  //       }
  //     )

  // }


  public imageList = [];
  img:any={};
  public isImg;
  getProductImages(proid){
    // var ProductID = localStorage.getItem('ProductID');
    var id={
      product_id: proid 
    }
    this.spinner.show();
    this.firewireservices.getProductImagesByProduct_id(id)
    .subscribe(
      response => {
        
        var imgList = response.data.result; 
        var str;
        var list = []

        if(response.data.status != 0){
          imgList.forEach(element => {
            
             str={path: appConfig.hs + element.image}
            list.push(str)
          });
          this.isImg = 1;
        }else{
          this.img="assets/images/no_image.jpg"
          this.isImg = 0;
             
        }
       
        // this.imageList = list;
        const slideItems = [
      { path: 'assets/images/1.png', title: 'Title 1' },
      { path: 'assets/images/a.jpg', title: 'Title 2' }, 
    ];

         this.imageList = list;
        // console.log(this.slider.items)
        this.spinner.hide();
        // console.log("cartlist",this.cartlist)

      },

    )
      
  }
  openPageModal() {
    this.productpage = true;
  }

  openFlagModal(item) {
    console.log(item)
    this.productId = item.product_id
    this.flagdata = true
  }

  flag(value) {
    console.log(value)
    this.flagvalue = value;
    this.changeFlagStatus();
  }


  changeFlagStatus() {
    // var formData = new FormData();
    // formData.append('product_id', this.productId.toString())
    // formData.append('flagstatus', this.flagvalue);
let sendData= {
  product_id: this.productId,
  flagstatus: this.flagvalue
}
console.log(sendData)
    this.spinner.show(); 
    this.firewireservices.flagStatus(sendData)
      .subscribe(
        response => { 
          if(response.data.status == 1){
              this.toastr.success(response.data.message)
              this.getAllProduct();
          }else{
            this.toastr.error(response.data.message)
          }
        },
        error => {
          console.log(error);
        });
  }

  userID:any={}
  public ShippingValues;
  openProductModel(data) { 
      console.log(data)
    this.product = data;
    this.product.resamt = this.product.reserve_amount 
    // this.product.pic_up =  this.product.address 
    console.log(this.product)
    this.userID = data.user_id;
    this.findAdress();
    // this.getAllLocation(); 
    // console.log(data)



     if ( data.fixrate !== "null" &&  data.fixrate != 'undefined') {
        this.ShippingValues = "2";
        this.product.shippingType = "2"
        this.product.fixrate = data.fixrate;
      } 
      if (data.pic_up !== "null" && data.pic_up != 'undefined') {
        this.ShippingValues = "1";
        this.product.shippingType = "1"
        this.product.pic_up =  data.pic_up;
      } 

    this.getProductImages(this.product.product_id)
    if (data.image != null) {
      this.previewUrl =appConfig.hs+ data.image
    } else {
      
      this.previewUrl = 'assets/images/no_image.jpg'
    }

    // console.log(this.previewUrl)
    if (data.color != null) { 
    var dd =data.color
    // var sub = dd.replace(/\"/g, "");
    // this.selectedColor = sub;
     var test = dd.toString().replace(/"/g, "");
      var sub = test.split(',');
      this.selectedColor = sub;
      // console.log(sub)
    }
    if (data.fin_system != null) {
      this.selectedSystem = data.fin_system;
    }
    if (data.size != null) {
      var ss =data.size
    var sizes = ss.toString()
    var st = sizes.split(',')
    this.selectedSize = st; 
    // console.log(st)
    }



    // console.log(data)
  }

  ShippMethod(data) {
    // console.log(data);
    if (data != undefined) {
      this.ShippingValues = data.value;
      if (this.ShippingValues == "1") {
        this.product.pic_up = null
        this.product.fixrate = null
      } 
      if(this.ShippingValues == "2") {
        this.product.fixrate = null
        this.product.pic_up = null
      }
    } else {
      // console.log("no data found");
      this.ShippingValues = 0;
    }
  }

  updateProduct() {
    // if(this.product.product_id != "" && this.product.pro_id != "" &&  
    //   this.product.name !="" &&  this.product.type!="" &&
    //    this.product.status!="" && this.product.location !="" &&  this.product.technology!="" &&  this.product.price !="" && this.product.brand_name!=""){

      
    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    formData.append('product_id', this.product.product_id); 
    formData.append('pro_id', this.product.pro_id); 
    formData.append('name', this.product.name);
    formData.append('type', this.product.type);
    formData.append('status', this.product.status);
    // formData.append('location', this.product.location);
    // formData.append('designtype', this.product.designtype); 
    formData.append('technology', this.product.technology);
    formData.append('price', this.product.price);
    formData.append('brand_name', this.product.brand_name); 
    formData.append('owner', "Firewire");
    formData.append('details', this.product.details);
    formData.append('boardstatus', this.product.boardstatus);
    formData.append('resamt', this.product.resamt);
    formData.append('color', this.product.color);
    formData.append('size',  this.product.size);
    formData.append('fin_system', this.product.fin_system);
    formData.append('shaper', this.product.shaper);

    if(this.product.fixrate != 'null' ||this.product.fixrate != undefined ||this.product.fixrate != "" ){
      formData.append('fixrate', this.product.fixrate);
    }
    if(this.product.pic_up != 'null' || this.product.pic_up != undefined || this.product.pic_up != ""){
      formData.append('pic_up', this.product.pic_up);
    }
    // if(this.product.tax != null){
    //   formData.append('tax', this.product.tax);
    // }
    this.spinner.show();
    this.firewireservices.updateProduct(formData)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            this.productpage = false;
            this.toastr.success(response.data.message)
            this.getAllProduct();
          } else {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            this.toastr.error(response.data.message);
          }
        },
        err => {
          console.log("error");
        }
      )
      //  }
  }

  getAllLocation() {
    var data = {
      user_id: this.userID 
    }
    // console.log(data)
    this.spinner.show();
    this.firewireservices.getLocationByuserID(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            var list = []
            data.forEach(element => {
              var str = { label: element.name, value: element.name }
              list.push(str)
            });
            this.locationdetailslist = list;
            // console.log("location", this.locationdetailslist);
            this.spinner.hide();
          } else {
            // console.log("no data found");
            this.spinner.hide();
          }

        }, err => {
          console.log("error");
          this.spinner.hide();

        }
      )
  }

  deleteModalvalue(item){
    this.product = item;
  }

  deleteProduct() {
    var datavalue = {
      product_id: this.product.product_id
    }
    this.spinner.show();
    this.firewireservices.DeleteProduct(datavalue)
      .subscribe(
        response => {
          // this.getAllProduct();
          if (response.data.status == 1) {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            // this.userpage = false;
            this.toastr.success(response.data.message)
            this.getAllProduct();
          } else {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            this.toastr.error(response.data.message)
          }
        },
        err => {
          console.log("error")
        }
      )
  }


  onFileSelect(event, param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.formData.append('file', file, file.name);  
      // console.log(file);
      this.fileid = param;
      this.uploadForm.get('profile').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
        // this.img = this.fileData.name;
      }
    }
  }

}
