import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { appConfig } from '../app.config';

@Injectable()
export class FireWireServices {

    // private baseUrl = appConfig.apiUrl;
    options: any;
    constructor(private http: HttpClient) { }


    registerInsert(user) {
        return this.http.post<any>(appConfig.apiUrl + '/auth/register', user)
            .map(res => {
                return res;
            });

    }
    getAllUsers() {
        return this.http.get<any>(appConfig.apiUrl + '/user/getAllUsers')
            .map(res => {
                return res;
            });
    }

    getAllSearch(data) {
        return this.http.post<any>(appConfig.apiUrl + '/user/searchUser', data)
            .map(res => {
                return res;
            });
    }

    UpdateUser(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/updateUser', id)
            .map(res => {
                return res;
            });
    }
    Updatefile(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/updateFile', id)
            .map(res => {
                return res;
            });
    }
    DeleteUser(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/deleteUser', id)
            .map(res => {
                return res;
            });
    }
    getUsersByID(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/getUsersByID', id)
            .map(res => {
                return res;
            });
    }

    // Product API

    AddProduct(product) {
        return this.http.post<any>(appConfig.apiUrl + '/product/createProduct', product)
            .map(res => {
                return res;
            });

    }

    getAllProduct() {
        return this.http.get<any>(appConfig.apiUrl + '/product/getAllProducts')
            .map(res => {
                return res;
            });

    }
    getAllSearchProduct(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/searchProduct', data)
            .map(res => {
                return res;
            });
    }
    // updateProduct(data)
    // {
    //     return this.http.post<any>(appConfig.apiUrl+'/product/updateProductAdmin',data)
    //     .map(res => {
    //         return res;
    //     });  
    // }
    updateProduct(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/updateProduct', data)
            .map(res => {
                return res;
            });
    }

    DeleteProduct(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/deleteProduct', id)
            .map(res => {
                return res;
            });
    }

    // Transaction API


    AddTransaction(product) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/createTransaction', product)
            .map(res => {
                return res;
            });

    }

    getAllTransaction() {
        return this.http.get<any>(appConfig.apiUrl + '/transaction/getAllTransactions')
            .map(res => {
                return res;
            });

    }
    getAllSearchTransaction(data) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/searchTransaction', data)
            .map(res => {
                return res;
            });
    }
    updateTransaction(data) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/updateTransaction', data)
            .map(res => {
                return res;
            });
    }
    DeleteTransaction(id) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/deleteTransaction', id)
            .map(res => {
                return res;
            });
    }


    // Location API

    AddLocation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/location/createLocation', data)
            .map(res => {
                return res;
            });

    }

    getAllLocation() {
        return this.http.get<any>(appConfig.apiUrl + '/location/getAllLocations')
            .map(res => {
                return res;
            });

    }
    getAllSearchLocation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/location/searchLocation', data)
            .map(res => {
                return res;
            });
    }

    getLocationByuserID(id) {
        return this.http.post<any>(appConfig.apiUrl + '/location/getLocationByuserID', id)
            .map(res => {
                return res;
            });
    }
    updateLocation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/location/updateLocation', data)
            .map(res => {
                return res;
            });
    }
    DeleteLocation(id) {
        return this.http.post<any>(appConfig.apiUrl + '/location/deleteLocation', id)
            .map(res => {
                return res;
            });
    }

    //Brands API

    AddBrand(data) {
        return this.http.post<any>(appConfig.apiUrl + '/brands/createBrand', data)
            .map(res => {
                return res;
            });

    }
    deleteBrand(data) {
        return this.http.post<any>(appConfig.apiUrl + '/brands/deleteBrand', data)
            .map(res => {
                return res;
            });

    }
    getAllBrands() {
        return this.http.get<any>(appConfig.apiUrl + '/brands/getAllBrands')
            .map(res => {
                return res;
            });
    }

    //Marketing APIS

    AddLogo(data) {
        return this.http.post<any>(appConfig.apiUrl + '/marketing/createLogo', data)
            .map(res => {
                return res;
            });

    }
    updateLogo(data) {
        return this.http.post<any>(appConfig.apiUrl + '/marketing/updateLogo', data)
            .map(res => {
                return res;
            });

    }
    deleteLogo(data) {
        return this.http.post<any>(appConfig.apiUrl + '/marketing/deleteLogo', data)
            .map(res => {
                return res;
            });

    }
    getAllLogos() {
        return this.http.get<any>(appConfig.apiUrl + '/marketing/getAllLogos')
            .map(res => {
                return res;
            });
    }

    //Slug URL API
    updateSlug(url) {
        return this.http.post<any>(appConfig.apiUrl + '/product/addSlug', url)
            .map(res => {
                return res;
            });
    }

    //Footer-settings API

    AddfotterSettings(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/addFooterSettings', data)
            .map(res => {
                return res;
            });
    }

    getAllFooterdata() {
        return this.http.get<any>(appConfig.apiUrl + '/product/getFooterData')
            .map(res => {
                return res;
            });
    }

    getuserByProductID(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getuserByProductID', id)
            .map(res => {
                return res;
            });
    }

    getProductByuserID(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getProductByuserID', data)
            .map(res => {
                return res;
            });
    }
    getProductImagesByProduct_id(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getProductImagesByProduct_id', data)
            .map(res => {
                return res;
            });
    }

    flagStatus(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/updateProductFlagStatus', data)
            .map(res => {
                return res;
            });
    }

    reviewStatus(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/updateReviewStatus', data)
            .map(res => {
                return res;
            });
    }

    getloggedInUserReview(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getloggedInUserReview', data)
            .map(res => {
                return res;
            });
    }

    getAllReview() {
        return this.http.post<any>(appConfig.apiUrl + '/product/getAllReview', '')
            .map(res => {
                return res;
            });
    }

    deleteReview(data) {
        return this.http.post<any>(appConfig.apiUrl + '/user/deleteReview', data)
            .map(res => {
                return res;
            });
    }

    deleteVideo(data) {
        return this.http.post<any>(appConfig.apiUrl + '/video/deleteVideo', data)
            .map(res => {
                return res;
            });
    }

    createVideo(data) {
        return this.http.post<any>(appConfig.apiUrl + '/video/createVideo', data)
            .map(res => {
                return res;
            });
    }

    updateVideo(data) {
        return this.http.post<any>(appConfig.apiUrl + '/video/updateVideo', data)
            .map(res => {
                return res;
            });
    }

    getAllVideo() {
        return this.http.post<any>(appConfig.apiUrl + '/video/getAllVideo', '')
            .map(res => {
                return res;
            });
    }

}