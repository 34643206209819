import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) myInputVariable: ElementRef;

  isPlay: boolean = false;
  videoid: any;
  uploadVideo: FormGroup;
  fileData: File = null;
  videoUrl: any = null;
  videoList = [];
  selectedVideo: any;
  files: File[] = [];
  previewVideo: any;
  public video : any = {}

  constructor(private firewireservices: FireWireServices, private router: Router,
    private toastr: ToastrService, private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,private http: HttpClient,) { }


  ngOnInit() {
    this.uploadVideo = this.formBuilder.group({
      video: ['']
    })
    this.getAllVideo();
  }

 
  getAllVideo() {
    this.spinner.show();
    this.firewireservices.getAllVideo()
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.videoList = response.data.result;
            this.videoList.forEach(element => {
              element.video_path = appConfig.hs + element.video_path
            });
          } else {
            this.videoList = [];
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
  }

  openVideoModel(data) {
    this.selectedVideo = data
    console.log(this.selectedVideo)
  }

  deleteVideo() {
    this.spinner.show();
    var data = {
      id: this.selectedVideo.id
    }
    this.firewireservices.deleteVideo(data)
      .subscribe(
        res => {
          if (res.data.verified == 1) {
            this.getAllVideo();
            this.toastr.success(res.data.status);
            this.spinner.hide();
          } else {
            this.toastr.warning(res.data.status)
            this.spinner.hide();
          }
        },
        error => {
          console.log(error);
          this.spinner.hide();
        });
  }

  onFileSelectVideo(event, param) {
    debugger
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.videoid = param;
      this.uploadVideo.get('video').setValue(file);
      this.fileData = file;
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/video\/*/) == null) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
        this.videoUrl = reader.result;
      }
    }
  }

  addVideoModel() {
    this.video = {}
    this.myInputVariable.nativeElement.value = "";
    this.uploadVideo.reset();

  }

  addVideo() {
    var formData = new FormData();
    this.spinner.show();
    formData.append('file', this.uploadVideo.get('video').value);
    formData.append('video_text', this.video.video_text);     
    this.firewireservices.createVideo(formData)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.getAllVideo();
            this.spinner.hide();
            this.toastr.success(response.data.message)
          } else {
            console.log("error");
            this.spinner.hide();
          }
          this.spinner.hide();
        }
      )
  }

}
