import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-footer-settings',
  templateUrl: './footer-settings.component.html',
  styleUrls: ['./footer-settings.component.css']
})
export class FooterSettingsComponent implements OnInit {
public footerList =[];
  constructor(private firewireservices : FireWireServices,private toastr: ToastrService,private formBuilder: FormBuilder,private spinner: NgxSpinnerService )  

  { 

    this.getAllFooterdata();
  }

  ngOnInit() {
  }

  getAllFooterdata() {
    this.spinner.show();

    this.firewireservices.getAllFooterdata()
      .subscribe(
        response => {
          setTimeout(function () {
            if(response.data.status === 0) {
              this.spinner.hide();
            }
          }.bind(this), response);
          this.footerList = response.data.result; 
          // console.log("productList",this.productList);
        }
      )
  }

  openProductModel(items){
    // console.log(items)
  }

}
