import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit {

  public brandList = [];
  brand:any={};
  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.getAllbrands();
  }

  ngOnInit() {
  }

  getAllbrands() {
    this.spinner.show();

    this.firewireservices.getAllBrands()
      .subscribe(
        response => {
          setTimeout(function () {
            this.spinner.hide();
          }.bind(this), response);
         
          var list = []

          response.data.result.forEach(element => {
            var str={brand_id:element.brand_id,image:appConfig.hs+element.image,brand_name:element.brand_name,brand_url:element.brand_url,status:element.status}

            list.push(str)
          });
          this.brandList = list; 
          // console.log("productList",this.productList);
          console.log(this.brandList)
        }
      )

  }

  openBrandModal(item){
    this.brand = item;
    console.log(this.brand)
    // this.Deletebrand()
  }
  Deletebrand() {
    var datavalue = {
      brand_id: this.brand.brand_id
    }
    this.spinner.show();
    this.firewireservices.deleteBrand(datavalue)
      .subscribe(
        response => {
          // this.getAllProduct();
          if (response.data.status == 1) {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            // this.userpage = false;
            this.toastr.success(response.data.message)
            this.getAllbrands();
          } else {
            setTimeout(function () {
              this.spinner.hide();
            }.bind(this), response);
            this.toastr.error(response.data.message)
          }
        },
        err => {
          console.log("error")
        }
      )
  }
}

