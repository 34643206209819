import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './view/header/header.component';
import { FooterComponent } from './view/footer/footer.component';
import { LoginComponent } from './view/login/login.component';
import { RegisterComponent } from './view/register/register.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { UsersComponent } from './view/users/users.component';
import { DataTableModule } from 'angular2-datatable';
import {  ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UsersFilterPipe } from './view/users/datafilterpipe';
import { ModalModule } from 'ngx-bootstrap';
import { TransactionComponent } from './view/transaction/transaction.component';
import { ProductComponent } from './view/product/product.component';
import { LogoutComponent } from './view/logout/logout.component';
import { UserService } from './services/user.service';
import { RoleGuardService } from './guards/roleGuardService';
import { AuthenticationService } from './services';
import { AuthGuard } from './guards';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CustomFormsModule} from 'ng2-validation';
import { FireWireServices } from './services/FireWireServices';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwtInterceptor } from './helper';
import { LocationComponent } from './view/location/location.component';

import { SocialLoginModule, AuthService } from 'angularx-social-login';
// import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

import {NgxPaginationModule} from 'ngx-pagination';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarketingComponent } from './view/marketing/marketing.component';
import { ToastrModule } from 'ngx-toastr';
// import { LogoComponent } from './view/logo/logo.component';
import { BrandComponent } from './view/brand/brand.component';
import {IvyCarouselModule} from 'angular-responsive-carousel'; 

import { ClipboardModule } from 'ngx-clipboard';
import { FooterSettingsComponent } from './view/footer-settings/footer-settings.component';
import { AgmCoreModule } from '@agm/core';
import { ReviewComponent } from './view/review/review.component';
import { VideoComponent } from './view/video/video.component';

// const config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider('17498439726-opbu80ciirqrgtml7tikfu5l3pudenfu.apps.googleusercontent.com')
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider('561602290896109')
//   },
//   // {
//   //   id: LinkedInLoginProvider.PROVIDER_ID,
//   //   provider: new LinkedInLoginProvider("78iqy5cu2e1fgr")
//   // }
// ]);

// export function provideConfig() {
//   return config;
// }


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    UsersComponent,
    TransactionComponent,
    ProductComponent,
    LogoutComponent,
    LocationComponent,
    MarketingComponent, 
    BrandComponent,
     FooterSettingsComponent,
     ReviewComponent,
     VideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTableModule, 
    NgSelectModule,
    FormsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({enableHtml: true}),
    NgxPaginationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCBvLOSy-pquUBu-q6hUZvLQUBhNddmDZs',
      libraries: ['places']
    }),
    // ToastrModule.forRoot({enableHtml: true}),
    // ToasterModule,
    DataTableModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    CustomFormsModule,
    // SocialLoginModule,
    ClipboardModule,
    CommonModule,
    HttpClientModule, 
    IvyCarouselModule
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    RoleGuardService,
    FireWireServices, 
    UserService,
    // ToastrService,
    // ToasterService,
    
    NgxSpinnerService,
    // {
    //   provide: AuthServiceConfig,
    //   useFactory: provideConfig
    // },
    UsersFilterPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true  },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
