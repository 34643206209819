import { Component, OnInit } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { IOption } from 'ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  public locationlist = [];
  location: any = {};
  model: any = {};
  public AddModel;
  public locationdetails = false;
  public locationsearchpage = false;
  public searchlocationList;

//   selectedUserType: any;
//   selectedCityId: number;
  
//   type = [
//     {id: 1, name: 'Retailer'},
//     {id: 2, name: 'Warehouse'},
//     {id: 3, name: 'Customer', disabled: true},
  
// ];

private options: string[] = ["Retailer", "Warehouse", "Customer"];
selectedUserType = "Retailer";

  // public utype: Array<IOption> = [
  //   {label: 'Belgium', value: 'BE'},
  //   {label: 'Luxembourg', value: 'LU'},
  //   {label: 'Netherlands', value: 'NL'}
  // ];

  constructor(private firewireservices : FireWireServices,private toastr: ToastrService,private spinner: NgxSpinnerService) {

    this.getAllLocation();
   }
   keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  ngOnInit() {
  }

  getAllLocation()
  {
    this.spinner.show(); 
    this.firewireservices.getAllLocation()
    .subscribe(
      response =>{
        var data = response.data.result
        if(data.length > 0)
        {                  
          this.spinner.hide();
          
        this.locationlist = response.data.result;
        this.searchlocationList = this.locationlist ;
        }else{
          console.log("no data found");
        }
        // this.searchlocationList = this.locationlist;
        // console.log("locationlist",this.locationlist)
      }
    )
  }
locationmodal()
{
  this.locationdetails = true;
}

  AllLocation()
  {
    var datavalue ={
      name : this.location.name,
      contact_name:this.location.contact_name,
      address: this.location.address,
      country : this.location.country,
      state : this.location.state,
      city: this.location.city,
      post_code : this.location.post_code,
      email : this.location.email ,
      phone_number : this.location.phone_number,
      user_type : this.selectedUserType,   

    }
this.spinner.show();
    // console.log(datavalue)
    this.firewireservices.AddLocation(datavalue)
    .subscribe(
      response =>{
        // this.getAllLocation();
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
            this.getAllLocation();

            this.toastr.success(response.data.message) 
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
      },
      err =>{
        console.log("error");
      }
    )
  }
  openLocationModel(data)
  {
    this.location = data;
    this.selectedUserType = data.user_type;
  }
  UpdateLocation()
  {

    if(this.location.loc_id != "" && this.location.name != "" && this.location.contact_name != ""
    && this.location.address != "" &&  this.location.country != "" && this.location.state != "" && this.location.city != ""
    && this.location.post_code != "" &&  this.location.email != "" && this.location.phone_number != "" ){

      if(this.location.loc_id != null && this.location.name != null && this.location.contact_name != null
      && this.location.address != null &&  this.location.country != null && this.location.state != null && this.location.city != null
      && this.location.post_code !=null &&  this.location.email != null && this.location.phone_number != null ){
  

    
    var datavalue ={
      location_id : this.location.location_id,
      loc_id:this.location.loc_id,
      name : this.location.name,
      contact_name:this.location.contact_name,
      address: this.location.address,
      country : this.location.country,
      state : this.location.state,
      city: this.location.city,
      post_code : this.location.post_code,
      email : this.location.email ,
      phone_number : this.location.phone_number,
      user_type : this.selectedUserType,
    
    }
    this.spinner.show();
    this.firewireservices.updateLocation(datavalue)
    .subscribe(
      response =>{
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
             
            this.getAllLocation();

            // this.locationdetails = false;
            this.toastr.success(response.data.message) 
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
        // this.getAllLocation();
        
      },
      err =>{
        console.log("error");
      }
    )
  }
}
}
  DeleteLocation()
  {
    var data ={
      location_id : this.location.location_id
    }
    this.spinner.show();
    this.firewireservices.DeleteLocation(data)
    .subscribe(
      response =>{
        // this.getAllLocation();
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
            this.toastr.success(response.data.message) 
            this.getAllLocation();
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
      },
      err=>{
        console.log("error");
      }
    )
  }

  searchLocationModal()
  {
    this.locationsearchpage = !this.locationsearchpage;
    }
  
  srch:any={}
    searchLocation()
    {
      var data ={
        name : this.srch.name
      }
      this.spinner.show(); 
      this.firewireservices.getAllSearchLocation(data)
      .subscribe(
        response =>{
          var data = response.data.result
          if(data.length > 0)
          {                   
          this.searchlocationList= data;
          this.spinner.hide();  
          }else{
            console.log("no data found");
            this.spinner.hide();  
          }      
         
        }
      )
    } 
}
