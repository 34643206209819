import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IOption } from 'ng-select';
import { SocialUser } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // ViewChild is used to access the input element. 
  
  @ViewChild('fileInput', {static: false}) 
    
// this InputVar is a reference to our input. 
  
  InputVar: ElementRef;

  public userlist;
  // users: any = {};
  public locationL: any = {};
  model: any = {};
  fileid : any;
  logoid : any;
  markid : any;
  logoUrl:any = null;
  marketingUrl:any = null;
  bmode:any={};
  file : any = {};
  public FileList;
  fileData: File = null;
  previewUrl:any = null;
  public editModel;
  userid = localStorage.getItem("user_id");
  profileimg = JSON.parse(sessionStorage.getItem('image'));
  uploadForm: FormGroup;
  uploadLogo: FormGroup;
  uploadmarketingImage : FormGroup;
  usersocial: SocialUser
  public market : any = {}
  public int = [
    { label: 'Fixed', value: 'Fixed' },
    { label: 'Interest', value: 'Interest' }
  ];
  users = [
    {id: 'anjmao', name: 'Anjmao'},
    {id: 'varnas', name: 'Tadeus Varnas'}
];
product: any = {};
brand : any = {};
utype: any = ['Florida', 'South Dakota', 'Tennessee', 'Michigan']
  // public utype: Array<NgOption> = [
  //   {label: 'Belgium', value: 'BE'},
  //   {label: 'Luxembourg', value: 'LU'},
  //   {label: 'Netherlands', value: 'NL'}
  // ];
 
  private options: string[] = ["Completed", "Wip", "Unsuccessful","Returned"];
  selectedPaymentType = "Completed";

  private optionstype: string[] = ["Admin","Customer"];
  selectedUserType = "Customer";

  private optionsstatus: string[] = ["Purchase", "Sale"];
  selectedTransactionType = "Purchase";

  private locoptions: string[] = ["Retailer", "Warehouse", "Customer"];
selectedType = "Retailer";
  constructor(private firewireservices : FireWireServices,private router :Router,private toastr: ToastrService,private formBuilder: FormBuilder,private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.getAllFooterdata();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    })
    this.uploadLogo= this.formBuilder.group({
      logo: ['']
    })
    this.uploadmarketingImage= this.formBuilder.group({
      marketingimage: ['']
    })
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
   
  updateUserProfile()
  {

    // console.log(this.userid);
    var data ={
      user_id : JSON.parse(this.userid)
    }

    this.firewireservices.getUsersByID(data)
    .subscribe(
      response =>{
        this.users = response.data;
        // console.log("users",this.users);
        this.editcliendataResponse(this.users)
      }
    )
  }

  editcliendataResponse(data) {
    // console.log("slugurl",data);

 
    this.model.first_name = data.result[0].first_name;
    this.model.last_name = data.result[0].last_name;
    this.model.user_type = data.result[0].user_type;
    this.model.email = data.result[0].email;    
     
  }


  onFileSelect(event,param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.formData.append('file', file, file.name);  
      // console.log(file);
      this.fileid = param;  
      this.uploadForm.get('profile').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
  
      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
        var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.previewUrl = reader.result; 
        // this.img = this.fileData.name;
      }
      }
  }

  updateUserProfiledata()
  {
    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    formData.append('user_id',JSON.parse(this.userid));
    formData.append('first_name',  this.model.first_name);
    formData.append('last_name',  this.model.last_name);
    formData.append('user_type', this.model.user_type);
    formData.append('email', this.model.email);
    
    // var data ={
    //       user_id : JSON.parse(this.userid),
    //       first_name : this.model.first_name,
    //       last_name : this.model.last_name,
    //       user_type:this.model.user_type,
    //       email:this.model.email,         
    //       image:this.uploadForm.get('profile').value
    //   }

    this.firewireservices.UpdateUser(formData)
    .subscribe(
      response =>{
      console.log("Updated Profile Successfully")
      },
      err =>{
        console.log("error");
      }
    )
  }

  register() {
    
    var datavalue ={
      first_name:this.model.first_name,
      last_name:this.model.last_name,
      user_name:this.model.user_name,
      address:this.model.addressy,
      country:this.model.countrys,
      state:this.model.statey,
      city:this.model.cityy,
      phone:this.model.phone,
      post_code:this.model.post_codee,
      // birthdate:this.model.birthdate,
      email:this.model.emaill,
      password:this.model.password,
      "created":new Date(),
      user_type :this.selectedUserType
    }
    // console.log(datavalue)
    this.spinner.show(); 
    this.firewireservices.registerInsert(datavalue)
      .subscribe(
        response => { 
           
        
          // this.getAllusers();
          if(response.data.status == 1){
            setTimeout(function() {
              this.spinner.hide();
              }.bind(this),response);
              // this.userpage = false;
              this.toastr.success(response.data.message)

              // this.getAllusers();
          }else{
            setTimeout(function() {
              this.spinner.hide();
              }.bind(this),response);
            this.toastr.error("Not Registered")
          }
          this.model = {}
        },
        error => {
          console.log(error);
        });
  }
  onFileSelectproduct(event,param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.formData.append('file', file, file.name);  
      // console.log(file);
      this.fileid = param;  
      this.uploadForm.get('profile').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
  
      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
        var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.previewUrl = reader.result; 
        // this.img = this.fileData.name;
      }
      }
  }
  AddProduct()
  { 

    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);    
    formData.append('name',  this.product.name);
    formData.append('type', this.product.type);
    formData.append('status', this.product.status);
    formData.append('location', this.product.location);
    formData.append('owner',  this.product.owner);
    formData.append('price',this.product.price);

    this.spinner.show(); 
    this.firewireservices.AddProduct(formData)
    .subscribe(
      response =>{
        // setTimeout(function() {
        //   this.spinner.hide();
        //   }.bind(this),response);
        // this.getAllProduct();
        if(response.data.status == 1){
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
            // this.userpage = false;
            this.toastr.success(response.data.message) 
            // this.getAllProduct();
        }else{
          setTimeout(function() {
            this.spinner.hide();
            }.bind(this),response);
          this.toastr.error(response.data.message)
        }
        this.product = {};
      },
      err =>{
        console.log("error")
      }
    )
  }
footerSet:any={};
  Addfootersetting(){

 
   
    var datavalue ={
      shopURL : this.footerSet.shopURL,
      wireURL:this.footerSet.wireURL,
      facebookURL: this.footerSet.facebookURL,
      instagramURL : this.footerSet.instagramURL,
      twitterURL : this.footerSet.twitterURL,  

    }
    
    // this.spinner.show();
    
    this.firewireservices.AddfotterSettings(datavalue)
    .subscribe(
      response =>{        
        if(response.data.status == 1){        
            this.spinner.hide();          
            this.toastr.success(response.data.message) 
            this.getAllFooterdata();
            document.getElementById('openModalFooter').click()

        }else{          
            this.spinner.hide();  
            this.getAllFooterdata();          
          this.toastr.error(response.data.message);
        }
        this.footerSet = {};
      },
      err =>{
        console.log("error");
        this.spinner.hide(); 
      }
    )
  
  }

  getAllFooterdata() {
    this.firewireservices.getAllFooterdata()
      .subscribe(
        response => {
          setTimeout(function () {
            if(response.data.status == 0) {
              console.log('ca')
              this.spinner.hide();
            }
            this.spinner.hide();
          }.bind(this), response);
          this.footerSet = response.data.result[0]; 
          // console.log("productList",this.productList);
        }
      )
  }

  addLocation()
  {

    if(this.locationL.name != undefined && this.locationL.contact_name!=undefined && 
      this.locationL.address != undefined && this.locationL.country !=undefined && 
      this.locationL.state !=undefined && this.locationL.city !=undefined &&
       this.locationL.post_code !=undefined && this.locationL.email !=undefined && this.locationL.phone_number!=undefined){

   

    var datavalue ={
      name : this.locationL.name,
      contact_name:this.locationL.contact_name,
      address: this.locationL.address,
      country : this.locationL.country,
      state : this.locationL.state,
      city: this.locationL.city,
      post_code : this.locationL.post_code,
      email : this.locationL.email ,
      phone_number : this.locationL.phone_number,
      user_type : this.selectedUserType,   

    }
    
this.spinner.show();
    // console.log(datavalue)
    this.firewireservices.AddLocation(datavalue)
    .subscribe(
      response =>{        
        if(response.data.status == 1){        
            this.spinner.hide();          
            this.toastr.success(response.data.message) 
            document.getElementById('openModalLocation').click()

        }else{          
            this.spinner.hide();            
          this.toastr.error(response.data.message);
        }
        this.locationL = {};
      },
      err =>{
        console.log("error");
        this.spinner.hide(); 
      }
    )
  }
  }


  onFileSelectLogo(event,param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.formData.append('file', file, file.name);  
      // console.log(file);
      this.logoid = param;  
      this.uploadLogo.get('logo').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];  
      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
        var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.logoUrl = reader.result;      
      }
      }
  }

  gotTO(){
    this.router.navigate(['logo'])
  }

  reset()  
  { 
    
// We will clear the value of the input  
// field using the reference variable. 
 
  
    this.InputVar.nativeElement.value = ""; 
    this.logoUrl = [];
    this.brand = []
  }

  Addbrand()
  { 

    if(this.uploadLogo.get('logo').value !=''){

   
    var formData = new FormData();
    formData.append('file', this.uploadLogo.get('logo').value);     
    formData.append('brand_name', this.brand.brand_name);   
    formData.append('brand_url', this.brand.brand_url);     
  

    this.spinner.show(); 
    this.firewireservices.AddBrand(formData)
    .subscribe(
      response =>{        
        if(response.data.status == 1){         
            this.spinner.hide();           
            this.toastr.success(response.data.message) 

            this.reset()
           
            document.getElementById('openModalBrand').click()
            

            // this.getAllProduct();
        }else{
           this.spinner.hide();          
          this.toastr.error(response.data.message)
        }
        // this.product = {};
      },
      err =>{
        console.log("error")
        this.spinner.hide(); 
      }
    )
  }else{

    this.toastr.warning('Please Choose File')
  }
  }

  onFileSelectMarketing(event,param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];     
      this.markid = param;  
      this.uploadmarketingImage.get('marketingimage').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
  
      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
        var reader = new FileReader();      
      reader.readAsDataURL(this.fileData); 
      reader.onload = (_event) => { 
        this.marketingUrl = reader.result; 

      
        // this.img = this.fileData.name;
      }
      }
  }
  reset1()  
  { 
    
// We will clear the value of the input  
// field using the reference variable. 
 
  
    this.InputVar.nativeElement.value = ""; 
    this.marketingUrl = []; 
  }
  AddMarketing(){
 
  if(this.uploadmarketingImage.get('marketingimage').value != ''){
    
    var formData = new FormData();
      formData.append('file', this.uploadmarketingImage.get('marketingimage').value); 
      formData.append('url', this.market.url);     

        this.spinner.show();
    this.firewireservices.AddLogo(formData)
    .subscribe(
      response =>{
        var data = response.data
        if(data.status == 1)
        {
          this.spinner.hide();           
          this.toastr.success(data.message) 
          this.reset1()
          document.getElementById('openModalMarket').click()

        }else{
          console.log("error");
          this.spinner.hide();  
        }

      }
    )

  }else{
    // console.log('Not Chosen')
    this.toastr.warning('Please Choose File')
  }
    
         
  
  }
}


